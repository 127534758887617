import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import pjson from "../package.json";
import store from "./store";

import IntegrationParams from "./components/IntegrationParams";

import "./css/index.scss";
const App = React.lazy(() => import("./App"));

const root = document.getElementById("webbot");

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<></>}>
      <IntegrationParams component={App} />
    </Suspense>
  </Provider>,
  root
);

console.log(`%c KIOS version: ${pjson.version}`, "background-color: #ffdd2d; color: #333;");
