import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import integrationReducer from "./integration/integrationReducer";
import activityReducer from "./activity/activityReducer";

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
});

const store = configureStore({
  reducer: {
    integration: integrationReducer,
    activity: activityReducer,
  },
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
