import { createSlice } from '@reduxjs/toolkit';
import ActivityState from '../../types/ActivityState';

const initialState: ActivityState = {
    fetchConfig: true,
    fetchConfigFailed: null,
    createResult: false,
    createResultFailed: null,
    stage: 'processing',
    mode: 'start',
    warningType: 'none',
    currentPageId: 0,
    answers: [],
    result: undefined,
    fetchResult: false,
    fetchResultFailed: false,
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        loadConfig: state => {
            state.fetchConfig = true;
        },
        loadConfigFailed: state => {
            state.fetchConfig = false;
            state.fetchConfigFailed = true;
        },
        loadConfigSuccess: (state, action) => {
            state.config = { ...state.config, ...action.payload };
            state.currentPageId = state.config!.pages[0].id;
            state.answers = state.config!.questions.map(question => {
                return {
                    id: question.id,
                    value: undefined,
                    isValid: question.type === 'html' ? true : !question.isRequired,
                };
            });
            state.fetchConfig = false;
        },
        createResult: state => {
            state.createResult = true;
        },
        createResultFailed: state => {
            state.createResult = false;
            state.createResultFailed = true;
        },
        createResultSuccess: (state, action) => {
            state.createResult = false;
            state.createResultFailed = null;
            state.result = action.payload;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setWarningType: (state, action) => {
            state.warningType = action.payload;
        },
        setCurrentPageId: (state, action) => {
            state.currentPageId = action.payload;
        },
        setStage: (state, action) => {
            state.stage = action.payload;
        },
        setAnswer: (state, action) => {
            const answer = action.payload;

            let answerIndex = state.answers.findIndex(a => a.id === answer.id)!;
            state.answers[answerIndex] = answer;
        },
        setAnswers: (state, action) => {
            state.answers = [...state.answers, ...action.payload];
        },
        setPageIsFilled: (state, action) => {
            let currentPage = state.config!.pages.find(page => page.id === action.payload.id)!;
            currentPage.isFilled = action.payload.isFilled;
        },
        setPageIsSkipped: (state, action) => {
            let currentPage = state.config!.pages.find(page => page.id === action.payload.id)!;
            currentPage.isSkipped = action.payload.isSkipped;
        },
        setHandlerResult: (state, action) => {
            state.config!.config.handlerResult = action.payload;
        },
        loadResult: state => {
            state.fetchResult = true;
        },
        loadResultFailed: state => {
            state.fetchResult = false;
            state.fetchResultFailed = true;
        },
        loadResultSuccess: (state, action) => {
            state.result = action.payload;
            state.fetchResult = false;
        },
    },
});

const { actions, reducer } = activitySlice;

export const {
    loadConfig,
    loadConfigFailed,
    loadConfigSuccess,
    setMode,
    setWarningType,
    setCurrentPageId,
    setStage,
    setAnswer,
    setHandlerResult,
    setAnswers,
    setPageIsFilled,
    setPageIsSkipped,
    createResult,
    createResultFailed,
    createResultSuccess,
    loadResult,
    loadResultFailed,
    loadResultSuccess,
} = actions;

export default reducer;
