import React, { useEffect } from 'react';
import { Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import features from '../release-features.json';
import AppState from '../types/AppState';
import { setIntegrationParams } from '../store/integration/integrationReducer';
import urlParams from '../services/urlParams';

const IntegrationParamsComponent: React.FC<any> = ({ component: Component, allowed, ...rest }) => {
    const dispatch = useDispatch();
    const { config } = useSelector((state: AppState) => state.activity);
    const { integration } = useSelector((state: AppState) => state);
    const { token, unti_id, activity_uuid } = integration;

    const root = document.getElementById('webbot');

    useEffect(() => {
        const iframeParams = urlParams.getUrlParams(window.location.href);

        const token = iframeParams.token || root?.getAttribute('data-user-token');
        const unti_id = iframeParams.unti_id || root?.getAttribute('data-unti-id');
        const activity_uuid =
            iframeParams.activity_uuid || root?.getAttribute('data-activity-uuid');
        const darkMode = iframeParams.darkMode || root?.getAttribute('data-dark-mode');

        dispatch(
            setIntegrationParams({
                token: token,
                unti_id: unti_id,
                activity_uuid: activity_uuid,
                darkMode: !!darkMode,
            })
        );
    }, [dispatch, root]);

    const isDemo = features.isDemo;

    if ((token && unti_id && activity_uuid) || isDemo || !config?.config) {
        return <Component {...rest} {...integration} />;
    } else {
        return <Result status="warning" title="Извините, произошла ошибка. Возвращайтесь позже." />;
    }
};

export default IntegrationParamsComponent;
